import { UserAuthDetail as OrvalUserAuthDetail, RoleEnum } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const UserAuthDetail = zod.object({
  email: zod.string().nullable().optional(),
  firstName: zod.string().max(150),
  lastName: zod.string().max(150),
  role: zod.nativeEnum(RoleEnum),
  picture: zod.string().nullable().optional(),
});

export type UserAuthDetail = zod.infer<typeof UserAuthDetail>;

export const toUserDetail = (user: OrvalUserAuthDetail): UserAuthDetail => {
  let result;
  try {
    result = UserAuthDetail.parse({
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      picture: user.picture,
    });
  } catch (e) {
    console.log('Error parsing user detail', e);
    throw e;
  }

  return result;
};
