'use client';

import { UserAuthDetail } from 'entity-convert/entity/user-auth-detail';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { paths } from '@/routes/paths';

interface AuthContextProps {
  isLoggedIn: boolean;
  login: (auth: { access: string; refresh: string; user: UserAuthDetail }) => void;
  logout: () => void;
  setIsLoggedIn: (value: boolean) => void;
  userInfo?: UserAuthDetail;
}

const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = (auth: { access: string; refresh: string; user: UserAuthDetail }) => {
    setIsLoggedIn(true);
    localStorage.setItem('auth', JSON.stringify(auth));
  };

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    localStorage.removeItem('auth');
  }, []);

  const getUserInfo = useCallback(() => {
    if (typeof window !== 'undefined') {
      const auth = localStorage.getItem('auth');
      if (auth) {
        return JSON.parse(auth).user;
      }
    }
    return null;
  }, []);

  useEffect(() => {
    const handleLogoutEvent = () => {
      logout();
      window.location.href = paths.home;
    };

    window.addEventListener('unauthorized', handleLogoutEvent);

    return () => {
      window.removeEventListener('unauthorized', handleLogoutEvent);
    };
  }, [logout]);

  useEffect(() => {
    const accessToken = localStorage.getItem('auth');
    if (accessToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, setIsLoggedIn, userInfo: getUserInfo() }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
